import React from 'react'
import "../style/components/longtext.scss";

const LongText = (props: { content: JSX.Element }) => {
  return (
    <section className='longtext'>
      <div className='wrapper'>
        {props.content}
      </div>
    </section>
  )
}

export default LongText